import { Component, DestroyRef, inject, OnInit, ViewChild } from '@angular/core';
import { PaginatorComponent } from '../../../../ui/modules/paginator/paginator.component';
import { DynamicGridComponent } from '../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { GroupsComponent } from '../../../reports/components/groups/groups.component';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import {
  ColorRange,
  DynamicGridAction,
  DynamicGridColumn,
  FilterChanges,
  FiltersConfig,
  GroupItem,
  GroupsConfig,
  ReportsData,
  ReportsRequest
} from '../../../../core/interface';
import { skip, switchMap, tap } from 'rxjs/operators';
import { LayoutService } from '../../../../layout/layout.service';
import { FiltersService } from '../../../../core/services/filters.service';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { ExchangeService } from '../../exchange.service';
import { GlobicaService } from '../../../../core/services/globica.service';
import { DspService } from '../../../../core/services/dsp.service';
import { TranslocoService } from '@jsverse/transloco';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SidebarFilterComponent } from '../../../../dynamic-modules/dynamic-filter/components/sidebar-filter/sidebar-filter.component';
import dayjs from 'dayjs';

const FIELD_COLOR_RANGES: { [key: string]: ColorRange[] } = {
  'filtered': [
    { min: 0, max: 25, color: 'blue' },
    { min: 26, max: 60, color: 'yellow' },
    { min: 61, max: 101, color: 'red' }
  ],
  'js': [
    { min: 0, max: 20, color: 'red' },
    { min: 21, max: 50, color: 'yellow' },
    { min: 51, max: 101, color: 'blue' }
  ],
  'proxy': [
    { min: 0, max: 20, color: 'blue' },
    { min: 21, max: 50, color: 'yellow' },
    { min: 51, max: 101, color: 'red' }
  ],
  'iframe': [
    { min: 0, max: 20, color: 'blue' },
    { min: 20, max: 50, color: 'yellow' },
    { min: 50, max: 101, color: 'red' }
  ],
  'headless': [
    { min: 0, max: 20, color: 'blue' },
    { min: 20, max: 50, color: 'yellow' },
    { min: 50, max: 101, color: 'red' }
  ],
  'uaEqual': [
    { min: 0, max: 20, color: 'red' },
    { min: 21, max: 50, color: 'yellow' },
    { min: 51, max: 101, color: 'blue' }
  ],
  'langEqual': [
    { min: 0, max: 20, color: 'red' },
    { min: 21, max: 50, color: 'yellow' },
    { min: 51, max: 101, color: 'blue' }
  ],
  'firstClicks': [
    { min: 0, max: 20, color: 'red' },
    { min: 21, max: 50, color: 'yellow' },
    { min: 51, max: 101, color: 'blue' }
  ],
  'secondClicks': [
    { min: 0, max: 20, color: 'blue' },
    { min: 21, max: 50, color: 'yellow' },
    { min: 51, max: 101, color: 'red' }
  ],
};

@Component({
  selector: 'adxad-compact-quality-report',
  templateUrl: './compact-quality-report.component.html',
  styleUrl: './compact-quality-report.component.scss'
})
export class CompactQualityReportComponent implements OnInit {
  @ViewChild(PaginatorComponent, { static: true })
  paginator: PaginatorComponent;

  @ViewChild(DynamicGridComponent, { static: true })
  dynamicGrid: DynamicGridComponent;

  @ViewChild(GroupsComponent, { static: true })
  groups: GroupsComponent;

  isLoading = false;
  isNoResults = false;

  private destroyRef = inject(DestroyRef);
  private requestGrid$ = new BehaviorSubject<ReportsRequest>(null);
  private loadGrid$: Observable<ReportsData> = this.requestGrid$.pipe(
    skip(1),
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(request => this.exchangeService.getQualityReport(request))
  );

  constructor(
    public layoutService: LayoutService,
    public filter: FiltersService,
    private sidebarModal: AdxadSidebarModal,
    private alerts: AdxadAlerts,
    private exchangeService: ExchangeService,
    public globica: GlobicaService,
    private dspService: DspService,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.subscribeFilter();
    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: ReportsData) => {
        this.isLoading = false;
        this.setGrid(result);
      }
    });
    this.paginator.init();
    this.loadData();
  }

  get isValidDates(): boolean {
    if (!this.groups.result.includes('dateHour')) return true;
    if (!this.filter.request.size || !this.filter.request.get('from')) return true;
    const from = dayjs(this.filter.request.get('from') as string);
    return dayjs().diff(from, 'days') < 103;
  }

  loadData(): void {
    zip(this.exchangeService.getQualityReportGroups(), this.dspService.getFilters('exchangeQuality'))
      .pipe(
        tap(() => (this.isLoading = true)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: ([groups, filters]: [GroupsConfig, FiltersConfig]) => {
          this.groups.setConfig(groups);
          this.filter.init({ data: filters.data });
          this.loadGrid();
          this.isLoading = false;
        },
        error: () => {}
      });
  }

  loadGrid(): void {
    if (!this.isValidDates) {
      this.alerts.error(this.translate.translate('dateHourAlert'), 5000);
      return;
    }
    this.requestGrid$.next(this.buildRequest());
  }

  private buildRequest(): ReportsRequest {
    let sort = this.dynamicGrid?.sort?.active;
    let direction = this.dynamicGrid?.sort?.direction;
    const groups = this.groups.result;
    const filter = this.filter.request;

    if (!sort || !this.dynamicGrid?.isUserSetSort) {
      const defaultGroup = ['dateHour', 'date', 'week', 'month'].find(x => groups.includes(x));
      if (defaultGroup) {
        sort = defaultGroup;
        direction = 'asc';
        this.dynamicGrid.setSort(sort, direction);
      }
    }

    return { limit: this.paginator.limit, page: this.paginator.page, filter, groups, sort, direction };
  }

  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.filter.markAsApplied();
    this.isNoResults = false;
  }

  subscribeFilter(): void {
    this.filter.changeFilter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: FilterChanges) => {
      if (data?.submit) {
        this.paginator.resetPage();
        this.loadGrid();
      }
    });
  }

  openFilter(): void {
    this.sidebarModal.open(SidebarFilterComponent, { data: this.filter });
  }

  applyFilter(): void {
    this.globica.trackEventGoals('buttonFiltersApply_click', {
      filters: this.filter.globicaFilterRequest,
      break_by: [this.groups.result]
    });
    this.paginator.resetPage();
    this.loadGrid();
  }

  clearFilters(): void {
    this.paginator.resetPage();
    this.filter.clearValues();
    this.loadGrid();
  }

  changeColumnsOrder(e: GroupItem[]): void {
    const newOrder = this.dynamicGrid.columns.slice(0, e.length);
    e.forEach((group, i) => {
      this.dynamicGrid.columns[i] = newOrder.find(x => x.id === group.id);
    });
    this.filter.markAsNotApplied();
  }

  toggleFilter({ value }: DynamicGridAction): void {
    this.filter.toggleFilterableValue(value.colId, value.value);
  }

  private setGrid(result: ReportsData): void {
    if (!result?.meta?.columns) {
      this.alerts.error(result?.message || this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    const additionalColumns = this.getAdditionalColumns();
    result.meta.columns = [...result.meta.columns.filter(col => col.type !== 'default'), ...additionalColumns];

    result.data = this.transformGridData(result.data);
    result.totals = null;

    if (result.data.length && result.meta.total > 0) {
      this.globica.trackEventGoals('resultExchangeCompactQualityStat_show', {
        rows_number: result.meta.total
      });
    }

    this.isNoResults = !result.data.length;
    if (!this.isNoResults) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    }
  }

  private getAdditionalColumns(): DynamicGridColumn[] {
    return [
      {
        id: 'traffic',
        header: { label: 'Traffic', sort: false },
        type: 'custom',
        customView: 'compactQualityData',
        format: {
          precision: 2,
          pipe: { format: '1.0-2', type: 'number' }
        }
      },
      {
        id: 'quality',
        header: { label: 'Quality', sort: false },
        type: 'custom',
        customView: 'compactQualityData',
        format: {
          precision: 2,
          pipe: { format: '1.0-2', type: 'number' }
        }
      }
    ];
  }

  private transformGridData(data: any[]): any[] {
    return data.map(item => ({
      ...item,
      traffic: [
        {
          label: 'Filtered:',
          value: item.ratioFilteredClicks,
          color: this.getColorClassForItem('filtered', item.ratioFilteredClicks)
        },
        {
          label: 'JS:',
          value: item.ratioJSClicks,
          color: this.getColorClassForItem('js', item.ratioJSClicks)
        },
        {
          label: 'Proxy:',
          value: item.ratioProxyClicks,
          color: this.getColorClassForItem('proxy', item.ratioProxyClicks)
        },
        {
          label: 'Iframe:',
          value: item.ratioIframeClicks,
          color: this.getColorClassForItem('iframe', item.ratioIframeClicks)
        },
        {
          label: 'Headless:',
          value: item.ratioHeadlessClicks,
          color: this.getColorClassForItem('headless', item.ratioHeadlessClicks)
        }
      ],
      quality: [
        {
          label: 'UA equal:',
          value: item.ratioUAEqualClicks,
          color: this.getColorClassForItem('uaEqual', item.ratioUAEqualClicks)
        },
        {
          label: 'Lang equal:',
          value: item.ratioLangEqualClicks,
          color: this.getColorClassForItem('langEqual', item.ratioLangEqualClicks)
        },
        {
          label: 'First clicks:',
          value: item.ratioFirstClicks,
          color: this.getColorClassForItem('firstClicks', item.ratioFirstClicks)
        },
        {
          label: 'Second clicks:',
          value: item.ratioSecondClicks,
          color: this.getColorClassForItem('secondClicks', item.ratioSecondClicks)
        }
      ]
    }));
  }

  private getColorClassForItem(field: string, value: number): string {
    const ranges = FIELD_COLOR_RANGES[field];
    if (ranges) {
      const range = ranges.find(r => value >= r.min && value < r.max);
      return range ? range.color : '';
    }
    return '';
  }
}
