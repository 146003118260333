import { Component } from '@angular/core';
import { GlobicaService } from '../../../core/services/globica.service';
import { LayoutService } from '../../../layout/layout.service';
import { EXCHANGE_ROUTE } from '../../../core/routes';

@Component({
  selector: 'app-exchange-layout',
  template: `
    <div
      class="adxad-main-container"
      [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
    >
      <header class="adxad-header-section">
        <adxad-tabs-nav>
          <adxad-tab-link
            *ngFor="let tab of tabs"
            [routerLink]="tab.link"
            routerLinkActive="active"
            (click)="globica.trackEventGoals(tab.globicaKey)"
          >
            {{ tab.name }}
          </adxad-tab-link>
        </adxad-tabs-nav>
      </header>

      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ['./layout.component.scss']
})
export class ExchangeLayoutComponent {
  tabs = [
    {
      name: "SSP's",
      stage: 'ssps',
      link: './' + EXCHANGE_ROUTE.ssps,
      globicaKey: 'linkExchangeSsp_click'
    },
    {
      name: "DSP's",
      stage: 'dsps',
      link: './' + EXCHANGE_ROUTE.dsps,
      globicaKey: 'linkExchangeDsp_click'
    },
    {
      name: 'Report',
      stage: 'report',
      link: './' + EXCHANGE_ROUTE.simpleReport,
      globicaKey: 'linkExchangeReport_click'
    },
    {
      name: 'Quality report',
      stage: 'qualityReport',
      link: './' + EXCHANGE_ROUTE.qualityReport,
      globicaKey: 'linkExchangeQualityReport_click'
    },
    {
      name: 'Compact quality report',
      stage: 'compactQualityReport',
      link: './' + EXCHANGE_ROUTE.compactQualityReport,
      globicaKey: 'linkExchangeCompactQualityReport_click'
    }
  ];

  constructor(
    public globica: GlobicaService,
    public layoutService: LayoutService
  ) {}
}
