<div
  [class.--menu-opened]="this.layoutService.isMenuStaticOpened"
  class="adxad-main-container"
>
  <header class="header">
    <section class="adxad-toolbar">
      <adxad-calendar
        (changeDates)="filter.changeCalendarDates($event)"
        [from]="filter.calendar?.value?.calendar.from"
        [to]="filter.calendar?.value?.calendar.to"
      ></adxad-calendar>

      <div class="adxad-toolbar__actions">
        <adxad-filter-actions
          (applyFilter)="applyFilter()"
          (clearFilters)="clearFilters()"
          (openFilter)="openFilter()"
          [count]="filter.count"
          [notApplied]="filter.isNotApplied"
        />
      </div>
    </section>
    <app-groups
      (changeGroupsOrder)="changeColumnsOrder($event)"
      class="w100"
    />
  </header>

  <adxad-info-block
    *ngIf="!isValidDates"
    [type]="'warning'"
  >
    {{ 'dateHourAlert' | transloco }}
  </adxad-info-block>

  <adxad-dynamic-grid
    (action)="toggleFilter($event)"
    (reload)="loadGrid()"
    [loading]="isLoading"
    [ngClass]="{'hide': isNoResults}"
  />

  @if (isNoResults) {
    <adxad-grid-not-found
      (clearFilters)="clearFilters()"
    />
  }

  <adxad-paginator (changePage)="loadGrid()" />
</div>
